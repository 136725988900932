import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "interfaces/atlas";
import { makeAPI } from "hooks/useAtlasAxios";
import { listPrices, listPriceSources, listProducts } from "atlasGraphql/atlas-queries";
import { IPrices, ISources } from "interfaces/price-tracker";

interface PriceTrackerState {
    prices: IPrices[],
    products: any[];
    priceSources: ISources[],
    loadingPrices: boolean;
    loadingProducts: boolean;
    loadingPriceSources: boolean;
}
interface IFetchProducts {
    listProducts: IProduct[];
  }
  interface IFetchPrices {
    listPrices: IPrices[];
  }
  interface IFetchPricesSources {
    listPriceSources: ISources[];
  }

const initialState: PriceTrackerState = {
  prices: [],
  products: [],
  priceSources: [],
  loadingPrices: false,
  loadingProducts: false,
  loadingPriceSources: false,
};
export const fetchProducts = createAsyncThunk("atlas/fetchProducts", async () => {
    try {
      const products = await makeAPI<any, IFetchProducts>({
        query: listProducts,
        variables: {
         orderBy: { createdOn: "DESC" },
        },
      });
      return products.data.listProducts;
    } catch (err) {
      console.log(err);
      return [];
    }
  });
  export const fetchPrices = createAsyncThunk("atlas/fetchPrices", async () => {
    try {
      const products = await makeAPI<any, IFetchPrices>({
        query: listPrices,
        variables: {
         orderBy: { createdOn: "DESC" },
        },
      });
      return products.data.listPrices;
    } catch (err) {
      console.log(err);
      return [];
    }
  });
  export const fetchPriceSources = createAsyncThunk("atlas/fetchPriceSources", async () => {
    try {
      const products = await makeAPI<any, IFetchPricesSources>({
        query: listPriceSources,
        variables: {
         orderBy: { createdOn: "DESC" },
        },
      });
      return products.data.listPriceSources;
    } catch (err) {
      console.log(err);
      return [];
    }
  });

const PriceTrackerSlice = createSlice({
  name: "priceTracker",
  initialState,
  reducers: {
    setPrices: (
        state,
        action: PayloadAction<{
        data: IPrices[];
        }>,
      ) => {
        state.prices = action.payload.data;
      },
    setProducts: (
        state,
        action: PayloadAction<{
          data: IProduct[];
        }>,
      ) => {
        state.products = action.payload.data;
      },
    setPriceSources: (
      state,
      action: PayloadAction<{
        data: ISources[];
      }>,
    ) => {
      state.priceSources = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.loadingPrices = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
      state.loadingPrices = false;
    });
    builder.addCase(fetchPrices.rejected, (state, action) => {
      state.prices = [...state.prices, ...(action.payload as IPrices[])];
      state.loadingPrices = false;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.loadingPrices = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loadingPrices = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products = [...state.products, ...(action.payload as IProduct[])];
      state.loadingPrices = false;
    });
    builder.addCase(fetchPriceSources.pending, (state) => {
      state.loadingPriceSources = true;
    });
    builder.addCase(fetchPriceSources.fulfilled, (state, action) => {
      state.priceSources = action.payload;
      state.loadingPriceSources = false;
    });
    builder.addCase(fetchPriceSources.rejected, (state, action) => {
      state.priceSources = [...state.priceSources, ...(action.payload as ISources[])];
      state.loadingPriceSources = false;
    });
    
  },
});

export const { setPrices, setProducts, setPriceSources } = PriceTrackerSlice.actions;

export default PriceTrackerSlice.reducer;

import { BottomNav, SideNavigation } from "constants/Navigation";
import { AccessLevels, type MemberModulesInput, type FlunaModules } from "API";
import { type NavigationItem } from "interfaces/Route";

export const getNoAccessModules = (userModules: MemberModulesInput[]) => {
  const navigationItems = SideNavigation.concat(BottomNav);

  const noAccessModules: NavigationItem[] = [];

  navigationItems.forEach((navItem) => {
    const userModule = userModules?.find((module) => module.name === navItem.module);

    if (!userModule || userModule.access === AccessLevels.noAccess) {
      noAccessModules.push(navItem);
    }
  });

  return noAccessModules;
};

export const blockUpdatesChecker = (userModules: MemberModulesInput[], updateModule: FlunaModules) => {
  const allowedModules = userModules.map((module) => (module.access === AccessLevels.fullAccess ? module.name : undefined)).filter(Boolean);

  const disable = !allowedModules.includes(updateModule);

  return disable;
};

export const routesByModule: Record<FlunaModules, Record<"exact" | "beginsWith", string[]>> = {
  kyc: { exact: ["/"], beginsWith: ["/company/"] },
  overview: { exact: ["/overview"], beginsWith: [] },
  atlas: { exact: ["/atlas"], beginsWith: ["/atlas"] },
  permissions: { exact: ["/permissions"], beginsWith: [] },
  lending: { exact: ["/loan-management", "/loan-payments"], beginsWith: ["/loan/", "/loan-renewal/", "/loan-management/"] },
  trade: { exact: ["/campaign-builder"], beginsWith: ["/campaign-builder"] },
  mca: { exact: ["/mc-accounts", "/transactions"], beginsWith: ["/mc-accounts/", "/transactions/"] },
  priceTracker: { exact: ["/price-tracker"], beginsWith: ["/price-tracker"] },
  statementParser: { exact: ["/statements", "/entity"], beginsWith: ["/entity/", "/categorization/"] },
  investorPortal: { exact: ["/investors", "/investments", "/ip-payments"], beginsWith: ["/investors/", "/investments/", "/ip-payments/"] },
};

export const checkAccessibleRoutes = (userModules: MemberModulesInput[], pathname: string) => {
  let exactRoutes: string[] = [];
  let beginWithRoutes: string[] = [];

  const noModuleAccess = userModules?.length === 0 || userModules?.every((module) => module?.access === AccessLevels.noAccess);

  userModules?.forEach((userModule) => {
    if (userModule.access !== AccessLevels.noAccess) {
      const allowedRoutes = routesByModule[userModule.name as FlunaModules];
      exactRoutes = [...exactRoutes, ...allowedRoutes.exact];
      beginWithRoutes = [...beginWithRoutes, ...allowedRoutes.beginsWith];
    }
  });

  const accessToRoute = exactRoutes.includes(pathname) || beginWithRoutes.some((route) => pathname.startsWith(route));

  return { noAccessToRoute: !accessToRoute, noModuleAccess };
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const fetchAccountTransactions = /* GraphQL */ `query FetchAccountTransactions($accountId: ID!) {
  fetchAccountTransactions(accountId: $accountId) {
    id
    description
    amount
    currency
    channel
    type
    date
    dateCreated
    accountId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchAccountTransactionsQueryVariables,
  APITypes.FetchAccountTransactionsQuery
>;
export const customGenericMailSender = /* GraphQL */ `query CustomGenericMailSender(
  $recipientMails: [String]
  $mailSubject: String
  $mailBody: String
  $mailSender: String
  $ccMails: [String]
  $bccMails: [String]
  $replyAddresses: [String]
) {
  customGenericMailSender(
    recipientMails: $recipientMails
    mailSubject: $mailSubject
    mailBody: $mailBody
    mailSender: $mailSender
    ccMails: $ccMails
    bccMails: $bccMails
    replyAddresses: $replyAddresses
  )
}
` as GeneratedQuery<
  APITypes.CustomGenericMailSenderQueryVariables,
  APITypes.CustomGenericMailSenderQuery
>;
export const getUsers = /* GraphQL */ `query GetUsers($id: ID!) {
  getUsers(id: $id) {
    id
    name
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getTextractRecords = /* GraphQL */ `query GetTextractRecords($id: ID!) {
  getTextractRecords(id: $id) {
    id
    bankName
    companyName
    country
    currency
    fileName
    processingStatus
    transactionFileName
    uploaderImage
    uploaderName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTextractRecordsQueryVariables,
  APITypes.GetTextractRecordsQuery
>;
export const listTextractRecords = /* GraphQL */ `query ListTextractRecords(
  $filter: ModelTextractRecordsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTextractRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bankName
      companyName
      country
      currency
      fileName
      processingStatus
      transactionFileName
      uploaderImage
      uploaderName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTextractRecordsQueryVariables,
  APITypes.ListTextractRecordsQuery
>;
export const getTextractEntities = /* GraphQL */ `query GetTextractEntities($id: ID!) {
  getTextractEntities(id: $id) {
    id
    name
    type
    count
    inflows
    outflows
    average
    country
    currency
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTextractEntitiesQueryVariables,
  APITypes.GetTextractEntitiesQuery
>;
export const listTextractEntities = /* GraphQL */ `query ListTextractEntities(
  $filter: ModelTextractEntitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  listTextractEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      count
      inflows
      outflows
      average
      country
      currency
      tags
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTextractEntitiesQueryVariables,
  APITypes.ListTextractEntitiesQuery
>;
export const getTextractTransactions = /* GraphQL */ `query GetTextractTransactions($id: ID!) {
  getTextractTransactions(id: $id) {
    id
    amount
    bank
    isCredit
    currency
    date
    modifiedDate
    formattedDate
    description
    entityId
    entityName
    entityType
    fileId
    fileName
    companyName
    processingBank
    additionalText
    channel
    chequeNo
    customerReference
    instrumentCode
    reference
    transactionType
    valueDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTextractTransactionsQueryVariables,
  APITypes.GetTextractTransactionsQuery
>;
export const listTextractTransactions = /* GraphQL */ `query ListTextractTransactions(
  $filter: ModelTextractTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTextractTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      bank
      isCredit
      currency
      date
      modifiedDate
      formattedDate
      description
      entityId
      entityName
      entityType
      fileId
      fileName
      companyName
      processingBank
      additionalText
      channel
      chequeNo
      customerReference
      instrumentCode
      reference
      transactionType
      valueDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTextractTransactionsQueryVariables,
  APITypes.ListTextractTransactionsQuery
>;
export const getFlunaMembers = /* GraphQL */ `query GetFlunaMembers($id: ID!) {
  getFlunaMembers(id: $id) {
    id
    firstName
    lastName
    email
    primaryTeam
    lastActive
    picture
    modules {
      name
      description
      access
      __typename
    }
    status
    googleUserCognitoId
    emailUserCognitoId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlunaMembersQueryVariables,
  APITypes.GetFlunaMembersQuery
>;
export const listFlunaMembers = /* GraphQL */ `query ListFlunaMembers(
  $filter: ModelFlunaMembersFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlunaMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      primaryTeam
      lastActive
      picture
      modules {
        name
        description
        access
        __typename
      }
      status
      googleUserCognitoId
      emailUserCognitoId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlunaMembersQueryVariables,
  APITypes.ListFlunaMembersQuery
>;
export const getWaiverRequests = /* GraphQL */ `query GetWaiverRequests($id: ID!) {
  getWaiverRequests(id: $id) {
    id
    loanId
    companyId
    paymentId
    currency
    lateFee
    requester
    requestedBy
    requesterImage
    requestNotes
    waiverRequested
    approver
    approvedAt
    approvedBy
    approverImage
    approvalNotes
    waiverApproved
    status
    supportingDocuments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWaiverRequestsQueryVariables,
  APITypes.GetWaiverRequestsQuery
>;
export const listWaiverRequests = /* GraphQL */ `query ListWaiverRequests(
  $filter: ModelWaiverRequestsFilterInput
  $limit: Int
  $nextToken: String
) {
  listWaiverRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      loanId
      companyId
      paymentId
      currency
      lateFee
      requester
      requestedBy
      requesterImage
      requestNotes
      waiverRequested
      approver
      approvedAt
      approvedBy
      approverImage
      approvalNotes
      waiverApproved
      status
      supportingDocuments
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWaiverRequestsQueryVariables,
  APITypes.ListWaiverRequestsQuery
>;
export const getDocuments = /* GraphQL */ `query GetDocuments($id: ID!) {
  getDocuments(id: $id) {
    id
    companyId
    key
    bucketName
    fileName
    name
    category
    complianceStatus
    expiresAt
    entityType
    entityId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentsQueryVariables,
  APITypes.GetDocumentsQuery
>;
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $filter: ModelDocumentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      key
      bucketName
      fileName
      name
      category
      complianceStatus
      expiresAt
      entityType
      entityId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
export const getKPIMetrics = /* GraphQL */ `query GetKPIMetrics($id: ID!) {
  getKPIMetrics(id: $id) {
    id
    day
    investmentData {
      totalInvestments
      totalActiveInvestments
      totalWalletBalance
      debtInvestmentAdded
      debtInvestmentWithdrawn
      totalInvestors
      activeInvestors
      investmentSize
      averageInvestmentSize
      weightedAverageCostOfCapital
      __typename
    }
    complianceData {
      numberOfUsersOnPlatform
      numberOfCompaniesOnPlatform
      numberOfOnboardedCompany
      numberOfKycApprovedCompany
      numberOfCompaniesWithBankAccountsConnected
      __typename
    }
    lendingData {
      totalActiveUSDLoansCount
      totalActiveNGNLoansCount
      totalActiveUSDLoansAmount
      totalActiveNGNLoansAmount
      totalActiveLoansAmount
      totalActiveNonNigerianLoansCount
      totalActiveNigerianLoansCount
      totalActiveNonNigerianLoansAmount
      totalActiveNigerianLoansAmount
      totalCummulativeLoansAmount
      monthlyInterestIncomeAllLoans
      monthlyInterestIncomeLt90Loans
      totalOngoingLoansAmount
      totalLateLoansAmount
      totalPaidLoansAmount
      loansDistributionByCountry {
        country
        loanAmount
        __typename
      }
      loansDistributionByCompany {
        company
        companyId
        loanAmount
        __typename
      }
      loansDistributionByLoan {
        loanName
        loanId
        renewalId
        applicationId
        loanType
        loanAmount
        __typename
      }
      __typename
    }
    tradeData {
      numberOfSuppliers
      numberOfBuyers
      brokerageRevenue
      numberOfTransactions
      numberOfBuyersServed
      grossMerchandisedValue
      __typename
    }
    MCAData {
      totalMCAOpened
      totalMCAInflowVolume
      totalMCAOutflowVolume
      MCAInflowVolume
      MCAOutflowVolume
      newFxVolume
      USDMCAAcounts
      EURMCAAcounts
      GBPMCAAcounts
      __typename
    }
    USDRateUsed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKPIMetricsQueryVariables,
  APITypes.GetKPIMetricsQuery
>;
export const listKPIMetrics = /* GraphQL */ `query ListKPIMetrics(
  $filter: ModelKPIMetricsFilterInput
  $limit: Int
  $nextToken: String
) {
  listKPIMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      day
      investmentData {
        totalInvestments
        totalActiveInvestments
        totalWalletBalance
        debtInvestmentAdded
        debtInvestmentWithdrawn
        totalInvestors
        activeInvestors
        investmentSize
        averageInvestmentSize
        weightedAverageCostOfCapital
        __typename
      }
      complianceData {
        numberOfUsersOnPlatform
        numberOfCompaniesOnPlatform
        numberOfOnboardedCompany
        numberOfKycApprovedCompany
        numberOfCompaniesWithBankAccountsConnected
        __typename
      }
      lendingData {
        totalActiveUSDLoansCount
        totalActiveNGNLoansCount
        totalActiveUSDLoansAmount
        totalActiveNGNLoansAmount
        totalActiveLoansAmount
        totalActiveNonNigerianLoansCount
        totalActiveNigerianLoansCount
        totalActiveNonNigerianLoansAmount
        totalActiveNigerianLoansAmount
        totalCummulativeLoansAmount
        monthlyInterestIncomeAllLoans
        monthlyInterestIncomeLt90Loans
        totalOngoingLoansAmount
        totalLateLoansAmount
        totalPaidLoansAmount
        loansDistributionByCountry {
          country
          loanAmount
          __typename
        }
        loansDistributionByCompany {
          company
          companyId
          loanAmount
          __typename
        }
        loansDistributionByLoan {
          loanName
          loanId
          renewalId
          applicationId
          loanType
          loanAmount
          __typename
        }
        __typename
      }
      tradeData {
        numberOfSuppliers
        numberOfBuyers
        brokerageRevenue
        numberOfTransactions
        numberOfBuyersServed
        grossMerchandisedValue
        __typename
      }
      MCAData {
        totalMCAOpened
        totalMCAInflowVolume
        totalMCAOutflowVolume
        MCAInflowVolume
        MCAOutflowVolume
        newFxVolume
        USDMCAAcounts
        EURMCAAcounts
        GBPMCAAcounts
        __typename
      }
      USDRateUsed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKPIMetricsQueryVariables,
  APITypes.ListKPIMetricsQuery
>;
export const entitiesByCurrency = /* GraphQL */ `query EntitiesByCurrency(
  $currency: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTextractEntitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  entitiesByCurrency(
    currency: $currency
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      count
      inflows
      outflows
      average
      country
      currency
      tags
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EntitiesByCurrencyQueryVariables,
  APITypes.EntitiesByCurrencyQuery
>;
export const transactionsByEntityId = /* GraphQL */ `query TransactionsByEntityId(
  $entityId: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTextractTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByEntityId(
    entityId: $entityId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      bank
      isCredit
      currency
      date
      modifiedDate
      formattedDate
      description
      entityId
      entityName
      entityType
      fileId
      fileName
      companyName
      processingBank
      additionalText
      channel
      chequeNo
      customerReference
      instrumentCode
      reference
      transactionType
      valueDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByEntityIdQueryVariables,
  APITypes.TransactionsByEntityIdQuery
>;
export const transactionsByRecordId = /* GraphQL */ `query TransactionsByRecordId(
  $fileId: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTextractTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByRecordId(
    fileId: $fileId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      bank
      isCredit
      currency
      date
      modifiedDate
      formattedDate
      description
      entityId
      entityName
      entityType
      fileId
      fileName
      companyName
      processingBank
      additionalText
      channel
      chequeNo
      customerReference
      instrumentCode
      reference
      transactionType
      valueDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByRecordIdQueryVariables,
  APITypes.TransactionsByRecordIdQuery
>;
export const userByEmail = /* GraphQL */ `query UserByEmail(
  $email: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlunaMembersFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      email
      primaryTeam
      lastActive
      picture
      modules {
        name
        description
        access
        __typename
      }
      status
      googleUserCognitoId
      emailUserCognitoId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;
export const waiverRequestsByLoan = /* GraphQL */ `query WaiverRequestsByLoan(
  $loanId: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWaiverRequestsFilterInput
  $limit: Int
  $nextToken: String
) {
  waiverRequestsByLoan(
    loanId: $loanId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      loanId
      companyId
      paymentId
      currency
      lateFee
      requester
      requestedBy
      requesterImage
      requestNotes
      waiverRequested
      approver
      approvedAt
      approvedBy
      approverImage
      approvalNotes
      waiverApproved
      status
      supportingDocuments
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WaiverRequestsByLoanQueryVariables,
  APITypes.WaiverRequestsByLoanQuery
>;
export const documentsByEntityId = /* GraphQL */ `query DocumentsByEntityId(
  $entityId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentsFilterInput
  $limit: Int
  $nextToken: String
) {
  documentsByEntityId(
    entityId: $entityId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      key
      bucketName
      fileName
      name
      category
      complianceStatus
      expiresAt
      entityType
      entityId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DocumentsByEntityIdQueryVariables,
  APITypes.DocumentsByEntityIdQuery
>;
export const metricsByDay = /* GraphQL */ `query MetricsByDay(
  $day: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelKPIMetricsFilterInput
  $limit: Int
  $nextToken: String
) {
  metricsByDay(
    day: $day
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      day
      investmentData {
        totalInvestments
        totalActiveInvestments
        totalWalletBalance
        debtInvestmentAdded
        debtInvestmentWithdrawn
        totalInvestors
        activeInvestors
        investmentSize
        averageInvestmentSize
        weightedAverageCostOfCapital
        __typename
      }
      complianceData {
        numberOfUsersOnPlatform
        numberOfCompaniesOnPlatform
        numberOfOnboardedCompany
        numberOfKycApprovedCompany
        numberOfCompaniesWithBankAccountsConnected
        __typename
      }
      lendingData {
        totalActiveUSDLoansCount
        totalActiveNGNLoansCount
        totalActiveUSDLoansAmount
        totalActiveNGNLoansAmount
        totalActiveLoansAmount
        totalActiveNonNigerianLoansCount
        totalActiveNigerianLoansCount
        totalActiveNonNigerianLoansAmount
        totalActiveNigerianLoansAmount
        totalCummulativeLoansAmount
        monthlyInterestIncomeAllLoans
        monthlyInterestIncomeLt90Loans
        totalOngoingLoansAmount
        totalLateLoansAmount
        totalPaidLoansAmount
        loansDistributionByCountry {
          country
          loanAmount
          __typename
        }
        loansDistributionByCompany {
          company
          companyId
          loanAmount
          __typename
        }
        loansDistributionByLoan {
          loanName
          loanId
          renewalId
          applicationId
          loanType
          loanAmount
          __typename
        }
        __typename
      }
      tradeData {
        numberOfSuppliers
        numberOfBuyers
        brokerageRevenue
        numberOfTransactions
        numberOfBuyersServed
        grossMerchandisedValue
        __typename
      }
      MCAData {
        totalMCAOpened
        totalMCAInflowVolume
        totalMCAOutflowVolume
        MCAInflowVolume
        MCAOutflowVolume
        newFxVolume
        USDMCAAcounts
        EURMCAAcounts
        GBPMCAAcounts
        __typename
      }
      USDRateUsed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MetricsByDayQueryVariables,
  APITypes.MetricsByDayQuery
>;
